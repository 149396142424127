<template>
  <div>
    <!--    根据路由进行访问-->
    <router-view/>

  </div>
</template>

<style>

</style>

<script>
export default {
  name:"app",
}
</script>
